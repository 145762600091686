import { useRouter } from 'next/router'
import * as React from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'

import { GATrackEvent } from 'utils/googleAnalytics'

import { useUserStore } from 'stores/user'

export const KnowledgeCenterEntrySection = () => {
  const router = useRouter()
  const { user } = useUserStore()

  const onNavigateToKnowledgeCenter = () => {
    GATrackEvent({
      eventName: 'knowledge_center',
      eventParams: {
        location: 'hero',
        is_login: !!user,
        user_id: user?.id ?? '',
      },
    })

    router.push('#') // TODO: @kodir change link to knowledge center
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8 bg-gray-100 py-12 px-6 lg:py-24">
      <h1 className="max-w-lg text-center font-serif text-xl text-white lg:text-2xl">
        Cari Tahu Lebih Lanjut mengenai Merdeka Belajar Kampus Merdeka Mandiri
      </h1>
      <Button color="white" size="lg" onClick={onNavigateToKnowledgeCenter}>
        <div className="flex items-center gap-2">
          <p className="text-gray-100">Pusat Informasi</p>
          <Icon className="!text-gray-100">arrow_forward</Icon>
        </div>
      </Button>
    </div>
  )
}
