import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'
import { useEffect, useState } from 'react'

import { firebase } from 'utils/firebase'

import { FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE } from 'constants/firebase'

export function useFirebaseRemoteConfigValue(
  key: keyof typeof FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE
) {
  const defaultValues = FIREBASE_REMOTE_CONFIG_DEFAULT_VALUE
  const [value, setValue] = useState<string | boolean | number>(
    defaultValues[key]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let eligible = true

      const remoteConfig = getRemoteConfig(firebase)
      remoteConfig.settings.minimumFetchIntervalMillis = 0

      fetchAndActivate(remoteConfig)
        .then(() => getValue(remoteConfig, key))
        .then((value) => {
          if (!eligible) {
            return
          }

          if (typeof defaultValues[key] === 'string') {
            setValue(value.asString())
          } else if (typeof defaultValues[key] === 'number') {
            setValue(value.asNumber())
          } else if (typeof defaultValues[key] === 'boolean') {
            setValue(value.asBoolean())
          }
        })

      return () => {
        eligible = false
      }
    }
  }, [key, defaultValues])

  return value
}
