import * as React from 'react'

import { Homepage } from 'app/Homepage/Homepage'

import { Homepage as Layout } from 'components/Layouts'
import { Seo } from 'components/Seo'

export const App = () => {
  return (
    <>
      <Layout>
        <Seo templateTitle="Home" />
        <Homepage />
      </Layout>
    </>
  )
}

export default App
