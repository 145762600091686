import clsx from 'clsx'
import Router from 'next/router'
import * as React from 'react'

import { Button } from '@wartek-id/button'

import { GATrackEvent } from 'utils/googleAnalytics'

import { useUserStore } from 'stores/user'

const onNavigateToRegister = () => {
  GATrackEvent({
    eventName: `register`,
    eventParams: {
      location: 'hero',
    },
  })
  Router.push(process.env.NEXT_PUBLIC_MBKM_URL + '/register')
}

export const HeadlineSection = () => {
  const { logged } = useUserStore()
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)

  React.useEffect(() => {
    setIsLoggedIn(logged())
  }, [logged])

  return (
    <div
      className={clsx(
        "flex flex-1 flex-col items-stretch justify-center gap-8 bg-[url('/images/header-bg-m.png')] py-10 px-6",
        "md:items-center md:bg-[url('/images/header-bg-m@2x.png')] md:py-48 md:px-24",
        "lg:bg-[url('/images/header-bg.png')]"
      )}
    >
      <h1 className="max-w-6xl text-left font-serif text-[28px] text-white md:text-center md:text-[40px]">
        Mandiri Melaksanakan Kegiatan di Luar Kampus untuk Bantu Persiapan
        Karier Mahasiswa
      </h1>

      {!isLoggedIn && (
        <Button
          color="blue"
          size="lg"
          onClick={onNavigateToRegister}
          className={'md:h-[50px] md:w-[184px]'}
        >
          Daftar Sekarang
        </Button>
      )}
    </div>
  )
}
