import * as React from 'react'

import { useFirebaseRemoteConfigValue } from 'utils/firebase/rc'

import {
  HeadlineSection,
  HowKMMCanHelpSection,
  KnowledgeCenterArticleRecommendationSection,
  KnowledgeCenterEntrySection,
  WhatIsKMMSection,
  WhoInvolvedInKMMSection,
} from './components/sections'

export const Homepage = () => {
  const showKnowledgeCenterSections = useFirebaseRemoteConfigValue(
    'KMM_SHOW_KNOWLEDGE_CENTER_ON_LANDING_PAGE'
  ) as boolean

  return (
    <div className="flex flex-1 flex-col">
      <HeadlineSection />
      <WhatIsKMMSection />
      <HowKMMCanHelpSection />
      <WhoInvolvedInKMMSection />
      {showKnowledgeCenterSections && <KnowledgeCenterEntrySection />}

      <KnowledgeCenterArticleRecommendationSection />
    </div>
  )
}
