import Link from 'next/link'
import * as React from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'

import { GATrackEvent } from 'utils/googleAnalytics'

import { useUserStore } from 'stores/user'

const articles = [
  {
    title: 'Apa itu MBKM Mandiri?',
    link: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us/articles/16745055294361',
  },
  {
    title: 'Apa perbedaan MKBM dengan MBKM Mandiri?',
    link: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us/articles/16745055294361',
  },
  {
    title: 'Apakah itu platform Pusat Pengalaman Mandiri?',
    link: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us/articles/16745202042649',
  },
  {
    title: 'Bagaimana cara mencari Perguruan Tinggi di platform?',
    link: 'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us/articles/16745300549913',
  },
]

const ArticleLinkItem = (props: {
  title: string
  link: string
  index: number
}) => {
  const { user, logged } = useUserStore()

  const onClickLink = (index: number) => () => {
    GATrackEvent({
      eventName: 'knowledge_center_article',
      eventParams: {
        is_login: logged(),
        user_id: user?.id ?? '',
        location: index,
        article_title: props.title,
      },
    })
  }

  return (
    <Link href={props.link} passHref>
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickLink(props.index)}
      >
        <div className="flex flex-1 flex-row items-center justify-between rounded-2xl border border-gray-20 px-4 py-5">
          <p className="text-gray-100">{props.title}</p>
          <Icon className="!text-gray-100">arrow_forward</Icon>
        </div>
      </a>
    </Link>
  )
}

export const KnowledgeCenterArticleRecommendationSection = () => {
  // const router = useRouter()
  const { user, logged } = useUserStore()

  const onNavigateToKnowledgeCenter = () => {
    GATrackEvent({
      eventName: 'knowledge_center_article',
      eventParams: {
        is_login: logged(),
        location: 'see_all',
        user_id: user?.id ?? '',
      },
    })

    window.open(
      'https://pusatinformasi.kampusmerdeka.kemdikbud.go.id/hc/en-us/categories/16061698755225-MBKM-Mandiri-Perguruan-Tinggi',
      '_blank'
    )
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8 px-10 py-8 lg:py-20">
      <div className="flex flex-1 flex-col items-stretch gap-8">
        <div className="flex flex-1 flex-col items-center justify-center gap-1">
          <h1 className="font-serif text-[28px] text-gray-90 lg:text-[40px]">
            Mulai Terlibat
          </h1>
          <p className="text-center text-lg text-gray-90">
            Rekomendasi artikel dari Pusat Informasi untuk memulai keterlibatan
            Perguruan Tinggi Anda di Pusat Pengalaman Mandiri
          </p>
        </div>
        <div className="flex flex-1 flex-col items-stretch gap-2 md:grid md:grid-flow-col md:grid-rows-2 md:gap-4">
          {articles.map((article, index) => (
            <ArticleLinkItem
              key={index}
              title={article.title}
              link={article.link}
              index={index + 1}
            />
          ))}
        </div>
      </div>

      <Button color="white" size="lg" onClick={onNavigateToKnowledgeCenter}>
        <div className="flex items-center gap-2">
          <p className="text-gray-100">Lihat Semua Panduan</p>
          <Icon className="!text-gray-100">arrow_forward</Icon>
        </div>
      </Button>
    </div>
  )
}
