import Image from 'next/image'
import * as React from 'react'

export const WhatIsKMMSection = () => {
  return (
    <div className="bg-blue-80">
      <div className="mx-auto flex max-w-[1440px] flex-col-reverse px-6 py-8 lg:flex-row lg:items-stretch lg:justify-between lg:px-0 lg:py-0">
        <div className="mx-auto flex flex-1 flex-col gap-3 lg:my-12 lg:max-w-[564px] lg:self-center">
          <h1 className="font-serif text-[28px] text-white lg:text-[40px]">
            Pusat Pengalaman Mandiri
          </h1>
          <p className="font-sans text-base text-white">
            Pusat Pengalaman Mandiri adalah{' '}
            <strong>
              titik temu Perguruan Tinggi dengan sesama Perguruan Tinggi
            </strong>
            . Mulai jalin kolaborasi bermakna yang akan bantu mahasiswa
            mendapatkan pengalaman di luar kampus guna mempersiapkan karier
            mereka.
          </p>
        </div>
        <div className="mt-10 mb-8 lg:mb-0 lg:mt-0 lg:h-[446px] lg:w-[594px] lg:flex-shrink-0">
          <Image
            src="/images/studying.png"
            width={1024}
            height={768}
            alt="what-is-kmm-img"
            className="rounded-xl lg:rounded-none"
          />
        </div>
      </div>
    </div>
  )
}
