import Image from 'next/image'
import * as React from 'react'

export const AboutKMMSection = () => {
  return (
    <div className="bg-blue-90">
      <div className="mx-auto flex max-w-[1440px] flex-1 flex-col px-6 py-8 lg:flex-row-reverse lg:items-stretch lg:justify-between lg:px-0 lg:py-0">
        <div className="flex flex-1 flex-col gap-3 lg:my-12 lg:mx-[98px] lg:max-w-2xl lg:self-center">
          <h1 className="font-serif text-xl text-white lg:text-2xl">
            Tentang Merdeka Belajar Kampus Merdeka Mandiri
          </h1>
          <p className="font-sans text-base text-white">
            Pusat Pengalaman Mandiri merupakan bagian dari program Kampus
            Merdeka yang menjembatani{' '}
            <strong>
              kerja sama antar Perguruan Tinggi melalui program Pertukaran
              Pelajar.
            </strong>{' '}
            Program ini bertujuan untuk membantu persiapan karier mahasiswa.
          </p>
          <p className="font-sans text-base text-white">
            Pusat Pengalaman Mandiri memiliki landasan hukum yaitu{' '}
            <strong>
              <u>UU no 22/2016 tentang Pendidikan Tinggi.</u>
            </strong>
          </p>
        </div>
        <div className="mt-8 mb-10 lg:mt-0 lg:mb-0 lg:h-[446px] lg:w-[594px] lg:flex-shrink-0">
          <Image
            src="/images/law.png"
            width={1024}
            height={768}
            alt="about-kmm-img"
          />
        </div>
      </div>
    </div>
  )
}
