import { CheckCircle } from 'phosphor-react'
import * as React from 'react'

const ChecklistItem = (props: { text: string }) => {
  return (
    <div className="flex flex-1 flex-row gap-4">
      <CheckCircle
        size={24}
        color="#0B5FEF"
        weight="fill"
        className="mt-[3px] flex-shrink-0"
      />
      <h2 className="font-serif text-xl text-gray-90">{props.text}</h2>
    </div>
  )
}

export const WhoInvolvedInKMMSection = () => {
  return (
    <div className="flex flex-1 flex-col items-center gap-8 bg-[url('/images/vec-1-m.png')] bg-cover py-8 px-4 lg:mx-[120px] lg:mt-10 lg:mb-24 lg:max-w-7xl lg:self-center lg:rounded-[40px] lg:bg-[url('/images/vec-1.png')] lg:px-24 lg:py-14">
      <h1 className="text-center font-serif text-[28px] text-white lg:text-[40px]">
        Siapa saja yang dapat terlibat?
      </h1>
      <div className="flex flex-1 flex-col gap-6 rounded-2xl bg-white py-6 px-4 sm:flex-row md:p-10">
        <div className="flex flex-1 flex-col gap-4">
          <ChecklistItem text="Dosen/Tenaga Kependidikan lain di Perguruan Tinggi" />
          <ChecklistItem text="Ketua Program Studi & Dekan" />
          <ChecklistItem text="Bagian Kerja Sama/Hubungan Internasional" />
        </div>
        <div className="flex flex-1 flex-col">
          <p>
            <strong>Kriteria Umum:</strong>
          </p>
          <ul className="list-disc">
            <li className="ml-4">
              <p>
                Sedang mencari{' '}
                <strong className="text-[#9477E7]">
                  kesempatan bekerja sama dengan Perguruan Tinggi lainnya.
                </strong>
              </p>
            </li>
            <li className="ml-4">
              <p>
                Sedang mencari kesempatan{' '}
                <strong className="text-[#9477E7]">pertukaran pelajar</strong>{' '}
                dengan Perguruan Tinggi lain.
              </p>
            </li>
            <li className="ml-4">
              <p>
                Ingin{' '}
                <strong className="text-[#9477E7]">mulai melaksanakan</strong>{' '}
                program Merdeka Belajar Kampus Merdeka Mandiri.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
