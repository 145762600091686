import clsx from 'clsx'
import Image from 'next/image'
import * as React from 'react'

interface HowKMMCanHelpSectionItemProps {
  title: string
  subtitle: JSX.Element
  image: {
    src: string
    width: number
    height: number
  }
  overlapOffset?: number
  theme?: 1 | 2
}

const HowKMMCanHelpSectionItem = ({
  title,
  subtitle,
  image,
  theme,
  overlapOffset,
}: HowKMMCanHelpSectionItemProps) => {
  return (
    <div
      className={clsx(
        'flex flex-1 flex-col md:flex-row md:items-center',
        theme == 1 ? 'md:flex-row' : 'md:flex-row-reverse'
      )}
    >
      <div className={clsx('z-10 self-center')}>
        <Image {...image} alt={title} className="object-contain" />
      </div>
      {!!overlapOffset && (
        <div
          className="hidden md:block"
          style={{
            marginRight: `-${overlapOffset}px`,
            marginLeft: `-${overlapOffset}px`,
          }}
        ></div>
      )}
      <div
        className={clsx(
          'flex max-w-lg flex-1 flex-col rounded-xl p-4 md:rounded-3xl md:p-14',
          theme == 1 ? 'bg-[#E6F2FF]' : 'bg-[#0B5FEF]'
        )}
      >
        <h2
          className={clsx(
            'font-serif text-xl  lg:text-2xl',
            theme == 1 ? 'text-gray-90' : 'text-white'
          )}
        >
          {title}
        </h2>
        <p
          className={clsx(
            'text-base ',
            theme == 1 ? 'text-gray-90' : 'text-white'
          )}
        >
          {subtitle}
        </p>
      </div>
    </div>
  )
}

export const HowKMMCanHelpSection = () => {
  const entries: HowKMMCanHelpSectionItemProps[] = [
    {
      title: 'Cari Kolaborator Potensial',
      subtitle: (
        <>
          Kemudahan untuk mencari{' '}
          <strong>kolaborator potensial dari sesama Perguruan Tinggi</strong>{' '}
          untuk memenuhi IKU 2.
        </>
      ),
      overlapOffset: 30,
      image: {
        src: '/images/how-1.png',
        width: 540,
        height: 240,
      },
    },
    {
      title: 'Pilihan Kolaborator yang Kredibel',
      subtitle: (
        <>
          Pilih dari ratusan{' '}
          <strong>Perguruan Tinggi yang siap menjalin kerja sama</strong> untuk
          memberikan pengalaman di luar kampus bagi mahasiswa.
        </>
      ),
      overlapOffset: 50,
      image: {
        src: '/images/how-2.png',
        width: 540,
        height: 366,
      },
    },
    {
      title: 'Hubungi dan Diskusi',
      subtitle: (
        <>
          Dapatkan <strong>kontak Perguruan Tinggi</strong> dengan mudah dan{' '}
          <strong>langsung hubungi</strong> untuk mulai berdiskusi mengenai
          kemungkinan kerja sama.
        </>
      ),
      overlapOffset: 20,
      image: {
        src: '/images/how-3.png',
        width: 528,
        height: 348,
      },
    },
    {
      title: 'Jalin Kerja Sama Bermakna',
      subtitle: (
        <>
          Bangun <strong>kemitraan kegiatan di luar kampus</strong> untuk bantu
          persiapan karier mahasiswa.
        </>
      ),
      image: {
        src: '/images/how-4.png',
        width: 524,
        height: 282,
      },
    },
  ]

  return (
    <div className="flex flex-1 flex-col items-center gap-10 px-4 py-10 md:py-20 md:px-10">
      <h1 className="max-w-xl self-center text-center font-serif text-[28px] text-gray-90 lg:max-w-3xl lg:text-[40px]">
        Bagaimana Pusat Pengalaman Mandiri dapat membantu Anda?
      </h1>
      {entries.map((entry, index) => {
        return (
          <div key={entry.title}>
            <HowKMMCanHelpSectionItem
              {...entry}
              theme={index % 2 === 0 ? 1 : 2}
            />
          </div>
        )
      })}
    </div>
  )
}
